@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');

body{
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}
.pageContainer{
    padding-left: 15px;
    padding-right: 15px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.text-center {
    text-align: center!important;
}
header{
    padding: 30px 15px;
}
.logo{
    text-align: center;
}
.logoImg{
    max-height: 130px;
    max-width: 100%;
}

.surveyQ-section{
    padding: 30px 0 50px;
}
.surveyQusetBox {
    max-width: 750px;
    margin: 0 auto 30px;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    border-radius: 5px;
}
.surveyQusetBoxHeader {
    padding: 30px;
    border-bottom: 1px solid #ddd;
}
.qTitle{
    font-weight: 700;
    font-size: 34px;
}
.surveyQusetBoxBody{
    padding: 30px;
}
.surveyQusetBoxBody label{
    margin: 0;
}
.formGrp{
    margin-bottom: 25px;
}
.inputDiv p {
    margin-bottom: 5px;
    position: relative;
    padding-left: 35px;
    font-size: 16px;
   
}
.inputDiv input{
    height: 20px;
    width: 20px;
    position: absolute;
    left: 0;
    top: 2px;
    cursor: pointer;
    accent-color: #008bd4;
}
.inputDiv label{
    cursor: pointer;
}
.formGrp textarea{
    min-height: 95px;
    resize: none;
}
.surveyQusetBoxBody .btn_sub {
    background: #008bd4;
    padding: 10px 20px;
    color: #fff;
    font-size: 18px;
}
.errMsg {
    color: red;
    display: block;
    font-size: 14px;
    font-weight: 600;
}

/* Footer Begin  */
.mainFooter{
    background: #f2f2f2;
}
.mainFooter {
    color: #000;
    padding: 45px 0;
    position: relative;
}
.f_quick_links {
    margin: 0 0 5px;
    padding: 0;
}
.f_quick_links li {
    color: #000;
    display: inline-block;
    font-family: Montserrat,sans-serif;
    font-size: 20px;
    font-weight: 700;
    list-style: none;
    padding: 0 5px;
}
.f_quick_links li a {
    color: #000;
    text-decoration: none;
}
.mainFooter p {
    color: #000;
    font-family: Montserrat,sans-serif;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 5px;
}


.confirmationPage{
    padding: 60px 0 80px;
    min-height: 50vh;
}
.confirmInner {
    box-shadow: 4px 8px 40px 8px rgba(88, 146, 255, 0.2);
    max-width: 800px;
    margin: 0 auto;
    padding: 50px;
}
.confirmInner .like{
    display: block;
    margin-bottom: 30px;
}
.confirmInner .like img{
    max-height: 190px;
}
.confirmInner h5{
    font-size: 30px;
    font-weight: bold;
    color: #000;
}

.confirmInner p{
    font-size: 18px;
    color: #aaa;
}
.backBtn {
    background: #008bd4;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    display: inline-block;
    font-size: 16px;
}

.backBtn:hover{
    text-decoration: none;
    color: #fff;
    background: #1475a8;
}




/* Responsive  */
@media screen and (max-width: 767.5px) {
    header {
        padding: 15px 15px;
    }
    .logoImg {
        max-height: 80px;
    }
    .f_quick_links li,
    .mainFooter p{
        font-size: 16px;
    }
    .surveyQ-section {
        padding: 30px 0;
    }
    .qTitle{
        font-size: 22px;
    }
    .surveyQusetBoxHeader,
    .surveyQusetBoxBody {
        padding: 25px 15px;
    }
    .confirmInner h5 {
        font-size: 20px;
    }
    .confirmInner p {
        font-size: 16px;
    }
    .confirmInner .like img {
        max-height: 130px;
    }
}